<template>
  <el-container>
    <div class="studentInfo" v-if="userInfo.role_id == '1'">
      <div class="studentNum">
        <p>现有隔离人员</p>
        <p>{{ total_number }}人</p>
      </div>
      <div class="governmentNum">
        <p>现有隔离点</p>
        <p>{{ governmentList.length }}个</p>
      </div>
      <div class="government_list">
        <div v-for="(item, index) in governmentList" :key="index">
          <el-radio v-model="government_id" @change="getList" :label="item.id"
            >{{ item.name
            }}<span style="color: #1e92ff; margin-left: 5px">{{
              item.person_num
            }}</span></el-radio
          >
        </div>
      </div>
    </div>
    <el-main>
      <el-form class="right" label-width="80px">
        <el-form-item label="房间号：">
          <el-input v-model="room_num"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="status" filterable placeholder="请选择隔离状态">
            <el-option
              v-for="item in statusList"
              :key="item.val"
              :label="item.label"
              :value="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字：">
          <el-input style="margin-right:20px;" v-model="keywords" placeholder="请输入姓名/电话"></el-input>
        </el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button @click="cancelSearch">清除搜索</el-button>
        <el-button @click="add">添加</el-button>
        <el-button @click="output">导出</el-button>
        <el-button @click="showImport = !0">导入</el-button>
      </el-form>
      <el-table :data="list" row-key="id" height="100%">
        <el-table-column
          prop="shipper_info.equipment_no"
          label="编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="room_num"
          label="房间号"
          align="center"
          width="120"
        >
          <template v-slot="{ row }">
            <div class="ellispsis">{{ row.room_num }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          align="center"
        ></el-table-column>
        <el-table-column prop="goods_name" label="性别" align="center">
          <template v-slot="{ row }">
            {{ row.sex ? "女" : "男" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="current_temperature"
          label="当前体温"
          align="center"
        >
          <template v-slot="{ row }">
            <span v-if="row.current_temperature"
              >{{ row.current_temperature }}℃</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="maximum_temperature"
          label="最高体温"
          align="center"
        >
          <template v-slot="{ row }">
            <span
              v-if="row.maximum_temperature"
              :style="{
                color: row.maximum_temperature >= 37.3 ? '#FF0000' : '#1e92ff',
              }"
              >{{ row.maximum_temperature }}℃</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="age"
          label="年龄"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="address"
          label="现住址"
          align="center"
          width="120"
        >
          <template v-slot="{ row }">
            <div class="ellispsis">{{ row.address }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_name"
          label="开始观察期"
          align="center"
          width="150"
        >
          <template v-slot="{ row }">
            <span v-if="row.period_observation">{{
              getDateformat(row.period_observation)
            }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="online_status" label="在/离线" align="center">
          <template v-slot="{ row }">
            <div class="online_status">
              <span v-show="row.online_status"></span>
              {{ row.online_status ? "在线" : "离线" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="电话"
          align="center"
          width="110"
        ></el-table-column>
        <el-table-column prop="goods_name" label="状态" align="center">
          <template v-slot="{ row }">
            <span :style="{ color: row.status ? '#FF0000' : '' }">{{
              row.status ? "隔离中" : "解除隔离"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="isolation_point"
          label="隔离点"
          align="center"
          width="120"
        >
          <template #header>
            <span style="color: #1e92ff">隔离点</span>
          </template>
          <template v-slot="{ row }">
            <div class="ellispsis">{{ row.isolation_point }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="shipper_info" label="负责人" align="center">
          <template #header>
            <span style="color: #1e92ff">负责人</span>
          </template>
          <template v-slot="{ row }">
            <span v-if="row.teacher_info.nickname">{{
              row.teacher_info.nickname
            }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="classify_name"
          label="电话"
          align="center"
          width="110"
        >
          <template #header>
            <span style="color: #1e92ff">电话</span>
          </template>
          <template v-slot="{ row }">
            <span v-if="row.teacher_info.mobile">{{
              row.teacher_info.mobile
            }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_name"
          label="创建日期"
          align="center"
          width="150"
        >
          <template #header>
            <span style="color: #1e92ff">创建日期</span>
          </template>
          <template v-slot="{ row }">
            {{ getDateformat(row.last_login_time) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_name"
          label="操作"
          align="center"
          width="210"
          fixed="right"
        >
          <template v-slot="{ row }">
            <el-button type="text" size="small" @click="getUserTemperature(row)"
              >体温</el-button
            >
            <el-button type="text" size="small" @click="relieve(row)"
              >解除设备</el-button
            >
            <el-button type="text" size="small" @click="sendInfo(0, row)"
              >发送短信</el-button
            >
            <el-button type="text" size="small" @click="handleEdit(row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-button type="primary" icon="el-icon-message" @click="sendInfo(1)"
          >发送短信</el-button
        >
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="rows"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total_number"
        >
        </el-pagination>
      </div>
      <el-dialog title="发送短信" :visible.sync="showSend" width="550px">
        <!-- <div>{{ infoContent }}</div> -->
        <el-form label-width="90px">
          <el-form-item label="发送内容：">
            <el-input
              v-model="infoContent"
              :rows="10"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showSend = !1">取 消</el-button>
          <el-button type="primary" @click="comfirmSend">确 定</el-button>
        </span>
      </el-dialog>
    </el-main>
    <import-file
      v-if="showImport"
      :url="$api.student.importStudent"
      fileUrl="student.xlsx"
      fileName="隔离人员模板.xlsx"
      :showImport="showImport"
      @importSuccess="importSuccess"
    ></import-file>
    <temperature
      v-if="showTemperature"
      :showTemperature="showTemperature"
      :temperatureInfo="temperatureInfo"
    ></temperature>
  </el-container>
</template>

<script>
import { getDateformat } from "@/utils/getDate";
import getDate from "@/utils/getDate";
import temperature from "@/components/temperature";
import importFile from "@/components/importFile";

export default {
  components: {
    temperature,
    importFile,
  },
  data() {
    return {
      page: 1,
      rows: 10,
      keywords: "",
      government_id: "",
      showTemperature: !1,
      showSend: !1,
      list: [],
      status: -1,
      infoContent: "",
      room_num:'',
      governmentList: [],
      total_number: 0,
      showImport: !1,
      government_radio: "",
      temperatureInfo: {},
      statusList: [
        {
          val: -1,
          label: "全部",
        },
        {
          val: 1,
          label: "隔离中",
        },
        {
          val: 0,
          label: "解除隔离",
        },
      ],
      id: "",
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  created() {
    this.getList();
    this.getGovernmentList();
  },
  methods: {
    importSuccess() {
      this.showImport = !1;
      this.getList();
    },
    //解除隔离
    relieve(row) {
      this.$axios
        .post(this.$api.student.unbindingBracelet, { id: row.id })
        .then((res) => {
          if (res.code == 0) {
            this.getList();
            this.$message.success("解除成功");
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //发送短信
    sendInfo(type, row) {
      if (type) {
        this.$confirm("确认向所有人发送短信？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$axios
            .post(this.$api.student.preList, {
              is_send: 1,
              keywords: this.keywords,
            })
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("发送成功");
              } else {
                this.$message.error(res.msg);
              }
            });
        });
      } else {
        this.$axios.post(this.$api.student.getSmsContent).then((res) => {
          if (res.code == 0) {
            this.showSend = !0;
            this.infoContent = res.result.content;
            this.id = row.id;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    comfirmSend() {
      let data = {
        id:this.id
      }
      if(this.infoContent) data.content = this.infoContent;
      this.$axios.post(this.$api.personSendSms, data).then((res) => {
        if (res.code == 0) {
          this.$message.success("发送成功");
          this.showSend = !1;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //获取人员体温
    getUserTemperature(row) {
      this.$axios
        .post(this.$api.student.getUserTemperature, { id: row.id })
        .then((res) => {
          if (res.code == 0) {
            let data = res.result;
            let month = data.month_user_temperature.slice(-10);
            let day = data.today_user_temperature.slice(-10);
            if (month.length || day.length) {
              day.map((item) => {
                let date = getDate.getDate(item.create_time * 1000);
                date.hour < 10 ? (date.hour = "0" + date.hour) : "";
                date.minutes < 10 ? (date.minutes = "0" + date.minutes) : "";
                date.time = date.hour + ":" + date.minutes;
                item.date = date;
              });
              month.map((item) => {
                let date = getDate.getDate(item.create_time * 1000);
                date.time = date.month + "月" + date.day + "日";
                item.date = date;
              });
              this.temperatureInfo.month_user_temperature = month;
              this.temperatureInfo.today_user_temperature = day;
              this.temperatureInfo.name = row.name;
              this.showTemperature = !0;
            } else {
              this.$message.warning("暂无体温内容");
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //获取隔离点
    getGovernmentList() {
      this.$axios
        .post(this.$api.student.stateNum, { rows: 10000 })
        .then((res) => {
          if (res.code == 0) {
            this.governmentList = res.result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList(val) {
      let data = {
        page: val ? 1 : this.page,
        rows: val ? 10 : this.rows,
        room_num:this.room_num
      };
      if (this.keywords) data.keywords = this.keywords;
      if (this.status >= 0) data.status = this.status;
      if (this.government_id) data.government_id = this.government_id;
      this.$axios.post(this.$api.student.preList, data).then((res) => {
        if (res.code == 0) {
          let list = res.result.list;
          list.map((item) => {
            item.teacher_info = item.teacher_info || {};
            item.shipper_info = item.shipper_info || {};
          });
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //导出数据
    output() {
      this.$axios
        .post(this.$api.student.output, {
          page: this.page,
          rows: this.rows,
        })
        .then((res) => {
          if (res.code == 0) {
            let a = document.createElement("a");
            a.href = res.result.link;
            a.id = "download";
            document.body.appendChild(a);
            a.click();
            let aDom = document.getElementById("download");
            document.body.removeChild(aDom);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    cancelSearch() {
      this.keywords = "";
      this.government_id = "";
      this.status = -1;
      this.room_num = ''
      this.getList();
    },
    handleEdit(row) {
      this.$router.push({
        path: "/home/AddLsolationPersonnel",
        query: {
          info: JSON.stringify(row),
        },
      });
    },
    handleSizeChange(val) {
      this.rows = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    add() {
      this.$router.push("/home/AddLsolationPersonnel");
    },
  },
};
</script>
<style lang="less" scoped>
.el-container {
  height: 100%;
}
.studentInfo {
  width: 180px;
  margin-right: 12px;
  background: #fff;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 0px;
  }
  .studentNum,
  .governmentNum {
    height: 120px;
    border-bottom: 1px solid #ebeef5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 20px;
    p {
      margin-bottom: 10px;
    }
  }
  .studentNum p:nth-child(2) {
    font-size: 20px;
    color: rgb(255, 0, 0);
    font-weight: 600;
  }
  .governmentNum p:nth-child(2) {
    font-size: 20px;
    color: #1e92ff;
    font-weight: 600;
  }
  .government_list div {
    margin-top: 20px;
    text-align: left;
    padding-left: 20px;
    label {
      /deep/ .el-radio__label {
        white-space: normal !important;
      }
    }
  }
}
.el-main {
  color: #333;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 800px;
  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ellispsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .right {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    justify-content: flex-end;
    .el-form-item {
      margin: 0;
    }
    .el-input,
    .el-select {
      width: 170px;
      text-align: left;
    }

    .el-button {
      border-radius: 19px;
    }
    .el-button--default {
      border-color: #409eff;
    }
  }
  .el-table {
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    margin-bottom: 10px;
  }
  .online_status {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      width: 6px;
      height: 6px;
      background: #1e92ff;
      border-radius: 50%;
      margin-right: 4px;
    }
  }
  /deep/ .el-dialog__body {
    text-align: left;
  }
}
</style>
